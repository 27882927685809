// Import packages
import React from "react";
import "./features.scss"

function Features(){
    let features = [
        {
            title:'Personality Test',
            description:'Take our free test to discover \n' +
                'your unique personality type\n' +
                'andreceive tailored ... \n',
            color:'#E7DFEA',
            textColor:'#8A5D74'
        },
        {
            title:'Emotional Journal',
            description:'Record your thoughts, feelings,\n' +
                'and emotions in a safe, private\n' +
                'space that’s accessible ...',
            color:'#F9EED7',
            textColor:'#E9D260'
        },
        {
            title:'People in Your Life',
            description:'Separate the people in your\n' +
                'life and see how each person\n' +
                'affects your mood and...',
            color:'#D6ECE3',
            textColor:'#71C8A6'
        },
        {
            title:'Community Support',
            description:'Connect with like-minded ind\n' +
                'ividuals and receive support\n' +
                'and encouragement from... \n',
            color:'#D9EAF0',
            textColor:'#7DC6D9'
        },
    ]
    return <div className={'features-block'}>
        <div className={'features-header'}>
            <div className={'features-title'}>
                Unique Functionality and Features
            </div>
            <div className={'features-description'}>
                With Oragic, you have access to a variety of features that make
                mental wellness a priority in your life.
                Here are just a few of the unique functionalities we offer:
            </div>
            <div className={'features-block-wrapper'}>
                {
                    features.map(item => {
                        return <div className={'blocks'} style={{backgroundColor:item?.color}}>
                            <div className={'blocks-title'}>
                                {item?.title}
                            </div>
                            <div className={'blocks-description'}>
                                {item?.description}
                            </div>
                            <div className={'blocks-see-more'} style={{color:item?.textColor,borderColor:item?.textColor}}>
                                see more
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>

}export default Features