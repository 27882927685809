// import packages
import React from "react";
import {useLocation} from "react-router-dom";

//import assets
import "./header.scss";
import {Dropdown, Menu} from "antd";
import {languages} from "../../constants/staticData";
import {en_img, LanguagesArr, Logo} from "../../assets/images";

// Import utils

function Header(props) {
    const location = useLocation();

    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return "en" !== item.code &&
                        <Menu.Item key={item.code}
                                   onClick={() => {
                                       // props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            {item.name}
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <div className={`header-wrapper `}>
        <section className={`header-box-wrapper`}>
            <a href={'/#main'} className="header-logo">
                <Logo/> <span>oragic</span>
            </a>

            <div className="nav-bar">
                <a className={`nav-item ${location.hash === '#main' ? 'active' : ''}`}
                   href={'/#main'}>
                    <p>Do I need?</p>
                </a>
                <a className={`nav-item ${location.hash === '#services' ? 'active' : ''}`}
                   href={'/#services'}>
                    <p>How does it work?</p>
                </a>
                <a className={`nav-item ${location.hash === '#about' ? 'active' : ''}`}
                   href={'/#about'}>
                    <p>About us</p>
                </a>
                <a className={`nav-item ${location.hash === '#app' ? 'active' : ''}`}
                   href={'/#app'}>
                    <p>App</p>
                </a>
                <div className={`nav-item`}>
                    <div className="languages-wrapper">
                        <span></span>
                        <Dropdown overlay={menuLanguage}
                                  className="language-button"
                                  overlayClassName={''}
                                  placement="bottomCenter"
                                  size={'small'}>
                            <div className="dropdown-menu-item">
                                <img src={en_img} alt=""/> En <LanguagesArr/>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </section>

        {/*Mobile Menu*/}

        <div className="mobile-nav-bar">
            <a href={'/#main'}>
                <Logo/>
            </a>
            <div className={`nav-bar-menu ${props.mobileMenuIsOpen ? 'active' : ''}`}
                 onClick={props.toggleMobileMenu}>
                <span className="line line-1"/>
                <span className="line line-2"/>
                <span className="line line-3"/>
            </div>
        </div>
    </div>
}


export default Header;
