import {SymbolIcon, User1, User2} from "../../../../assets/images";
import Slider from "react-slick";
import {solve} from "../../../../constants/staticData";
import {SliderNextButton, SliderPrevButton} from "../../../uiElements/buttons/Buttons";
import React, {useRef, useState} from "react";
import './community.scss'


function Community(){
    const community = [
        {
            fullName:'Anna Gevorgyan',
            address:' New York, USA',
            description:'“ Lorem ipsum dolor sit amet, consectetur adipiscing eiusmod tempor incididunt. Lorem ipsum dolor amet. Lorem ipsum dolor amet! ”',
            img:User1
        },
        {
            fullName:'Sevak Galstyan',
            address:' New York, USA',
            description:'“ Lorem ipsum dolor sit amet, consectetur adipiscing eiusmod tempor incididunt. Lorem ipsum dolor amet. Lorem ipsum dolor amet! ”',
            img:User2
        },
        {
            fullName:'Anna Gevorgyan',
            address:' New York, USA',
            description:'“ Lorem ipsum dolor sit amet, consectetur adipiscing eiusmod tempor incididunt. Lorem ipsum dolor amet. Lorem ipsum dolor amet! ”',
            img:User1
        },
        {
            fullName:'Sevak Galstyan',
            address:' New York, USA',
            description:'“ Lorem ipsum dolor sit amet, consectetur adipiscing eiusmod tempor incididunt. Lorem ipsum dolor amet. Lorem ipsum dolor amet! ”',
            img:User2
        },
        {
            fullName:'Anna Gevorgyan',
            address:' New York, USA',
            description:'“ Lorem ipsum dolor sit amet, consectetur adipiscing eiusmod tempor incididunt. Lorem ipsum dolor amet. Lorem ipsum dolor amet! ”',
            img:User1
        },
        {
            fullName:'Sevak Galstyan',
            address:' New York, USA',
            description:'“ Lorem ipsum dolor sit amet, consectetur adipiscing eiusmod tempor incididunt. Lorem ipsum dolor amet. Lorem ipsum dolor amet! ”',
            img:User2
        },
    ]
    let sliderRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);


    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (solve?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };


    let settings = {
        dots: false,
        arrows:false,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "130px 0 0 0",
        ref: slider => (sliderRef = slider),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false

                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode:false
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode:false
                }
            }
        ]
    };
    return <div className={'community-block'}>
        <div className={'community-left-block'}>
            <div className={'community-subtitle'}>
                Review
            </div>
            <div className={'community-title'}>
                Join the Oragic Community.
            </div>
            <div className={'community-description'}>
                Ready to start your mental wellness journey? Join
                the Oragic community today and connect with a
                supportive community that understands.

                Download our app now and take the first step
                towards finding inner peace, tracking your emotions,
                and living your best life.
            </div>
        </div>
        <div className={'community-right-block'}>
            <Slider {...settings}>
                {community?.map(item => {
                    return <div className="community-wrapper" key={item?.id}>
                        <SymbolIcon/>
                        <div className={'solve-block-description'}>
                            {item?.description}
                        </div>
                        <div className={'community-user'}>
                            <div className={'img-block'}>
                                <img src={item?.img} alt=""/>
                            </div>
                            <div className={'user-info'}>
                                <div className={'user-fullName'}>
                                    {item?.fullName}
                                </div>
                                <div className={'user-address'}>
                                    {item?.address}
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </Slider>
            {!!community?.length && <div className={'buttons'}>
                <SliderPrevButton cbPrev={() => prevSlide(slideIndex)}/>

                <SliderNextButton cbNext={() => nextSlide(slideIndex)}/>
            </div>}
        </div>
    </div>
}export default Community