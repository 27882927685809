// import packages
import React from "react";
import Main from "../componenets/homepage/main/Main";
import Services from "../componenets/homepage/services/Services";
import About from "../componenets/homepage/about/About";

//Import Components;
// import App from "../components/homepage/app/App";
// import Services from "../components/homepage/services/Services";
// import Main from "../components/homepage/main/Main";
// import About from "../components/homepage/about/About";
// import Info from "../components/homepage/info/Info";
// import SolveSlider from "../components/homepage/services/solveSlider/SolveSlider";

// Import utils

function Homepage(){
    return <div className="homepage-wrapper">
        <Main/>
        <Services/>
        <About/>
        {/*<App/>*/}
        {/*<Info staticTexts={staticTexts}/>*/}
    </div>
}
export default Homepage;

