// Import packages
import React, { useState} from "react";
import Header from "../../componenets/header/Header";
import Footer from "../../componenets/footer/Footer";


function Layout(props) {
    const {children} = props
    const [mobileMenuIsOpen,setMobileMenuIsOpen] = useState(false)

    function toggleMobileMenu() {
        setMobileMenuIsOpen(!mobileMenuIsOpen)
    }

    return <div className="layout-wrapper">
        <Header
            mobileMenuIsOpen={mobileMenuIsOpen}
            toggleMobileMenu={toggleMobileMenu}
        />
        <div className={`main-content`}>
            {children}
        </div>
        <Footer/>
        {/*<MobilseMenu*/}
        {/*    toggleMobileMenu={toggleMobileMenu}*/}
        {/*    className={mobileMenuIsOpen ? 'expand_on' : ''}*/}
        {/*/>*/}
    </div>
}
export default Layout;
