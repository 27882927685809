// Import packages
import React from "react";

// Import assets
import './services.scss';
import SolveSlider from "./solveSlider/SolveSlider";
import Solution from "./solution/Solution";
import Features from "./features/Features";

// Import components

// Import utils

const Services = (props) => {
    const {staticTexts} = props;

    return <section className={`services-wrapper`} id={'services'}>
        <SolveSlider/>
        <Solution/>
        <Features/>
    </section>
};

export default Services;