// import packages
import React from "react";

//import assets
import "./about.scss";
import AboutTeam from "./aboutTeam/AboutTeam";
import WhyOragic from "./whyOragic/WhyOragic";
import Community from "./community/Community";


// Import Components
// import {getPropsFromState} from "../../../redux/mapStateToProps";


const About = (props) => {
    const {staticTexts} = props;

    return <section className={`about-wrapper`} id='about'>
        <AboutTeam/>
        <WhyOragic/>
        <Community/>
    </section>
};

export default About;
