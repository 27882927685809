// import packages
import React from "react";

//import assets
import "./whyOragic.scss";


// Import Components


function WhyOragic(){
    const oragic = [
        {
            title:'Easy to Use:',
            description:'Our app is intuitive and user-friendly, so you can start tracking your emotions right away'
        },
        {
            title:'Safe and Secure:',
            description:'We take your privacy seriously and ensure that all your information is protected with state-of-the-art security features.'
        },
        {
            title:'Personalized:',
            description:'Our AI technology ensures that you receive tailored quotes, challenges, and insights that match your unique personality type.'
        },
    ]
    return <div className={'why-oragic-block'}>
        <div className={'why-oragic-header'}>
            <div className={'why-oragic-subtitle'}>
                Lorem ipsum
            </div>
            <div className={'why-oragic-title'}>
                Why Choose Oragic?
            </div>
        </div>
        <div className={'why-oragic-wrapper'}>
            {
                oragic?.map(item => {
                    return <div className={'oragic-item'}>
                        <div className={'oragic-title'}>
                            {item?.title}
                        </div>
                        <div className={'oragic-description'}>
                            {item?.description}
                        </div>
                    </div>
                })
            }
        </div>
    </div>

}export default WhyOragic