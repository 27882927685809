// Import packages
import React from "react";
import "./aboutTeam.scss"
import {HeartLogo, TeamImg} from "../../../../assets/images";


function AboutTeam(){
    return <div className={'aboutTeam-block'}>
        <div className={'aboutTeam-background'}/>
        <div className={'aboutTeam-left-block'}>
            <div className={'aboutTeam-subTitle'}>
                About us
            </div>
            <div className={'aboutTeam-title'}>
                Welcome to team oragic!
            </div>
            <div className={'aboutTeam-description'}>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                do eiusmod tempor incididunt. Lorem ipsum dolor
                amet. Lorem ipsum dolor sit amet.

                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt
            </div>
            <div className={'aboutTeam-icon'}>
              <HeartLogo/>
            </div>
        </div>
        <div className={'aboutTeam-right-block'}>
            <TeamImg/>
        </div>
    </div>
}export default AboutTeam