import React from "react";
import {en_img, hy_img, ru_img,Img4,Img3,Img2,Img1} from "../assets/images";

/*
*
*Languages
*
* */

export const languages = [
    {
        "code": "hy",
        "name": "Հայ",
        "isMain": true,
        "icon": hy_img,
    },
    {
        "code": "en",
        "name": "Eng",
        "isMain": false,
        "icon": en_img,
    },
    {
        "code": "ru",
        "name": "Ру",
        "isMain": false,
        "icon": ru_img,
    }
];

export const solve = [
    {
        icon:Img1,
        title:'Feeling overwhelmed with emotions',
        description:'Many people struggle with ' +
            'overwhelming emotions, whether \n' +
            'it’s anxiety, depression, or just feeling\n' +
            ' \n'
    },
    {
        icon:Img2,
        title:'Lack of emotional support:',
        description:'People often feel like they don’t \n' +
            'have anyone to turn to for emotional \n' +
            'support, whether it’s because they'
    },
    {
        icon:Img3,
        title:'Difficulty understanding one’s emotions:',
        description:'Everyone needs a little encouragement \n' +
            'from time to time, and Oragic offers \n' +
            'a variety of motivational quotes'
    },
    {
        icon:Img4,
        title:'Need for motivation and inspiration:',
        description:'It’s common to struggle with \n' +
            'identifying and understanding your\n' +
            ' own emotions,especially during '
    }
]

/*
*
* Slider Data
*
* */


/*
*
* Services
*
* */
