// Import packages
import React from "react";
import "./solution.scss"
import {SolutionImg} from "../../../../assets/images";


function Solution(){
    return <div className={'solution-block'}>
        <div className={'solution-left-block'}>
            <div className={'solution-subTitle'}>
                Lorem
            </div>
            <div className={'solution-title'}>
                Our Solution
            </div>
            <div className={'solution-description'}>
                Oragic is more than just a digital diary - it’s your
                personal mental wellness coach. Our app is built on
                the latest AI technology, which means it can detect

                your personality type, generate motivational quotes
                tailored to your needs, and provide challenges to help
                you grow and develop...
            </div>
            <div className={'solution-see-more'}>
                see more
            </div>
        </div>
        <div className={'solution-right-block'}>
            <SolutionImg/>
        </div>
    </div>
}
export default Solution