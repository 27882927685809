// Import packages
import React from "react";
import {Route, Routes} from "react-router-dom";
import Layout from "../containers/layout/Layout";
import Hompages from "../containers/Hompages";

// Import utils

// Import pages


export default function RoutesBlocks() {
    return <Layout>
        <Routes>
            <Route path="/" element={<Hompages/>}/>
        </Routes>
    </Layout>
}


