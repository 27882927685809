import './footer.scss'
import {FacebookIcon, FooterLogo, InstagramIcon, LinkedinIcon, SubscribeIcon, TwitterIcon} from "../../assets/images";
import React from "react";
import {useLocation} from "react-router-dom";
import {InputGroup} from "../uiElements/input-group/inputGroup";
import {useState} from "react";
import isEmail from "validator/es/lib/isEmail";


function Footer (){
    const location = useLocation();
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)

    function getInputValues(e) {
        let { name, value } = e.target
        setEmail(value)
        setError(false)
    }

    function addSubscribers() {
        if (email && isEmail(email)) {
            setEmail('')
        } else {
            setError(true)
        }
    }
    return <div className={'footer-wrapper'}>
        <FooterLogo/>
        <div className={'footer-top-block'}>
            <div className={'first-block'}>
                <div className={'first-block-title'}>
                    Repo for tracking suggestions, edits and poide feedback and ideas.
                </div>
                <div className={'social-block'}>
                    <FacebookIcon/>
                    <InstagramIcon/>
                    <TwitterIcon/>
                    <LinkedinIcon/>
                </div>
            </div>
            <div className={'second-block'}>
                <div className={'subscribe-title'}>
                    Subscribe Our Newsletter
                </div>
                <div className={`input-wrapper ${error ? 'invalid' : ''}`}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={email}
                        error={error}
                        name={'firstName'}
                        placeholder={`Enter email address`}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <button className={'send-btn'} onClick={addSubscribers}>
                        <SubscribeIcon/> Subscribe
                    </button>
                </div>
            </div>
            <div className={'third-block'}>
                <div className={'third-block-title'}>
                    Quick Links
                </div>
                <div className={'nav-items-block'}>
                    <a className={`nav-item ${location.hash === '#main' ? 'active' : ''}`}
                       href={'/#main'}>
                        <p>Do I need?</p>
                    </a>
                    <a className={`nav-item ${location.hash === '#services' ? 'active' : ''}`}
                       href={'/#services'}>
                        <p>How does it work?</p>
                    </a>
                    <a className={`nav-item ${location.hash === '#about' ? 'active' : ''}`}
                       href={'/#about'}>
                        <p>About us</p>
                    </a>
                </div>
            </div>
        </div>
        <div className={'footer-bottom-block'}>
            <div className={'company-block'}>
                © 2022 oragic
            </div>
            <div className={'privacy-policy'}>
                Privacy policy
            </div>
        </div>
    </div>

}export default Footer

