import {LeftArrLogo, RightArrLogo} from "../../../assets/images";
// Import packages
import React from "react";

// Import utils


export function SliderPrevButton(props) {
    const {className, cbPrev, disabledPrev} = props;
    return !disabledPrev && <div className={`prev-btn ${className}`} onClick={cbPrev}>
        <LeftArrLogo title={''} className={'prev-icon'}/>
        {/*<img src={require('../../assets/images/icons/ic_arow.svg')}*/}
        {/*     className={'prev-icon'} alt=""/>*/}
    </div>
}

export function SliderNextButton(props) {
    const {className,  cbNext, disabledNext} = props;
    return !disabledNext && <div className={`next-btn ${className}`} onClick={cbNext}>
        <RightArrLogo title={''} className={'prev-icon'}/>
        {/*<img src={require('../../assets/images/icons/ic_arow.svg')}*/}
        {/*     className={'next-icon'} alt=""/>*/}
    </div>
}