// import packages
import React from "react";


//import assets
import "./main.scss";
import {MainBg, MainImg} from "../../../assets/images";

// Import Components
// import {getPropsFromState} from "../../../redux/mapStateToProps";

// Import utils


const Main = (props) => {
    const {staticTexts} = props;

    return <section className={`main-wrapper`} id={'main'}>
        <div className={'main-bg-wrapper'}>
            <img src={MainBg} alt="" className={'main-bg'}/>
        </div>
        <div className="main-content">
            <div className="main-title">
                The only journey is the journey within
            </div>
            <div className="main-description">
                Just because no one else can heal or do your inner work for you,
                doesn’t mean you can, should, or need to do it alone.
            </div>
            <a href={navigator?.platform !== 'MacIntel' && navigator?.platform !== 'iPhone'
                ? ''
                : ''}
               target={'_blank'}
               className="download-btn">
                Download
            </a>
        </div>
        <div className={'main-img-wrapper'}>
            <div className={`main-img`}>
                <img src={MainImg} alt={''}/>
            </div>
        </div>
    </section>
};


export default Main;
