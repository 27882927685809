// Import packages
import React from "react";
import {solve} from "../../../../constants/staticData";
import {useRef, useState} from "react";
import Slider from "react-slick";
import {SliderNextButton, SliderPrevButton} from "../../../uiElements/buttons/Buttons";
import {HeartLogo} from "../../../../assets/images";
import './solveSilder.scss'


function SolveSlider (){
    let sliderRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);


    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (solve?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };


    let settings = {
        dots: false,
        arrows:false,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        ref: slider => (sliderRef = slider),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return <div className={'solveSlider-wrapper'}>
        <div className={'solveSlider-header'}>
            <div className={'solveSlider-title'}>
                The Problem We Solve <HeartLogo/>
            </div>
            <div className={'solveSlider-description'}>
                Do you often feel like no one understands you or can relate to how you’re
                feeling?Do you need someone to talk to but don’t want to burden your friends
                and family? which is why we’ve created Oragic, a virtual diary.
            </div>
        </div>
        <div className={'solveSlider'}>
            <Slider {...settings}>
                {solve?.map(item => {
                    return <div className="solve-block" key={item?.id}>
                            <img src={item?.icon}
                                         title={item?.title ? item?.title : ''}
                                         alt={item?.altAttribute ? item?.altAttribute : ''}
                                         className="solve-img"/>
                        <div className={'solve-block-title'}>
                            {item?.title}
                        </div>
                        <div className={'solve-block-description'}>
                            {item?.description}
                        </div>

                    </div>
                })}
            </Slider>
            {!!solve?.length && <div className={'buttons'}>
                <SliderPrevButton cbPrev={() => prevSlide(slideIndex)}/>

                <SliderNextButton cbNext={() => nextSlide(slideIndex)}/>
            </div>}
        </div>
    </div>

}
export default SolveSlider